import clsx from "clsx";
import exp from "constants";
import React, { useEffect, useState } from 'react';
import { ContentSlide } from "./components/ContentSlide";
import { ParticlesSlide } from "./components/ParticlesSlide";

function App() {
  const [expanded, setExpaned] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 800) {
      document.body.style.setProperty('--total-height', window.innerHeight+'px');
    }
  }, []);

  return (<>
    <header>
      <div className="wrapper wrapper__header">
        <a className={clsx('logo', !expanded && 'white')} href='https://redcollar.ru'>
          <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6169 7.17685L27.5715 1.2157L29.1765 2.82063L19.3746 13.8831L14.5596 9.12569L9.80192 13.8831L0 2.82063L1.60499 1.2157L14.6169 7.17685Z" fill="#F51B1B"/>
          </svg>
          <span>red collar</span>
        </a>
        <button className={clsx(!expanded && 'white')} role='button' type='button' onClick={() => setExpaned(!expanded)}>
          {expanded ? 'закрыть' : 'программа'}
        </button>
      </div>
    </header>
    <div className="flow-wrap">
      <ParticlesSlide expanded={expanded}/>
      <ContentSlide expanded={expanded}/>
    </div>
  </>);
}

export default App;
