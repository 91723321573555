import { BreakStruct, SpeakerStruct } from "./ConfigTypes";

export const Rows: (SpeakerStruct | BreakStruct)[] = [
  {
    name: 'веня в.',
    text: 'о манифесте и платформе бренда',
    time: '17:00',
    image: 0,
  },
  {
    name: 'лена т.',
    text: 'капча нечаянно нагрянет, когда \n' +
      'ее совсем не ждешь',
    time: '17:12',
    image: 4,
  },
  {
    name: 'семен ф.',
    text: 'мои принципы интересного дизайна',
    time: '17:24',
    image: 2,
  },
  {
    name: 'кирилл р.',
    text: 'оказывается, у нас так много общего: \n' +
      'о пересечениях воркфлоу визуального и звукового дизайна',
    time: '17:36',
    image: 16,
  },
  {
    name: 'даша з.',
    text: 'UX-писательство',
    time: '17:48',
    image: 12,
  },
  {
    name: 'лера ю.',
    text: 'куда смотреть в отпуске',
    time: '18:00',
    image: 7,
  },
  {
    time: '18:12'
  },
  {
    name: 'артём ф.',
    text: 'как обучаться новому',
    time: '18:24',
    image: 1,
  },
  {
    name: 'андрей а.',
    text: 'скиллсет дизайнера в направлении продуктов/сервисов',
    time: '18:36',
    image: 14,
  },
  {
    name: 'димас к.',
    text: 'осознанный рост дизайнера',
    time: '18:48',
    image: 10,
  },
  {
    name: 'надя к.',
    text: 'дизайнер как часть общего процесса',
    time: '19:00',
    image: 3,
  },
  {
    name: 'катя ж.',
    text: 'везде успеть или как я трекаю \n' +
      'по 10 задач в день',
    time: '19:12',
    image: 8,
  },
  {
    name: 'лерика м.',
    text: 'влияние окружающей визуальной среды на насмотренность',
    time: '19:24',
    image: 15,
  },
  {
    time: '19:36'
  },
  {
    name: 'макс с.',
    text: 'три слона профессионализма',
    time: '19:48',
    image: 9,
  },
  {
    name: 'люда п.',
    text: 'саморефлексия, как полезная практика для дизайнера',
    time: '20:00',
    image: 17,
  },
  {
    name: 'вика ф.',
    text: 'дизайн-эксперименты и проверки своих решений',
    time: '20:12',
    image: 11,
  },
  {
    name: 'эмиль б.',
    text: '2 года с одним клиентом',
    time: '20:24',
    image: 5,
  },
  {
    name: 'марина с.',
    text: 'что общего у дизайна интерфейсов и обычных вещей',
    time: '20:36',
    image: 6,
  },
  {
    name: 'ксюша д.',
    text: 'дизайн-культура внутри команды',
    time: '20:48',
    image: 13,
  },
];
