import { MouseEvent, useState } from "react";
import clsx from "clsx";
import { Rows } from "../config/Config";
import { ParticleWrapper } from "./ParticleWrapper";

interface SlideProps {
  expanded: boolean;
}

const PEOPLE = Rows
  .map(item => {
    if ('image' in item) {
      return item;
    }
    return null;
  })
  .filter(item => item !== null)
  .sort(() => Math.random() - 0.5);

export function ParticlesSlide({expanded}: SlideProps) {
  const [index, setIndex] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);

  const openTally = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    (window as any).Tally.openPopup('n9q6V4');
  }

  const onIndexChange = (idx: number) => {
    if (idx !== -1) {
      setIndex(idx);
    }
    setVisible(idx !== -1);
  }

  return (<div className={clsx('particles', expanded && 'collapse')}>
    <div className="particles__scene">
      <ParticleWrapper lookup={PEOPLE.map(item => item?.image ?? 0)} onIndexChange={onIndexChange} />
      <div className={clsx('text', visible && 'active')}>
        <span>
          {PEOPLE[index]?.name} <br/><br/>
          {PEOPLE[index]?.text}
        </span>
      </div>
    </div>
    <div className="particles__name">
      <div className="wrapper">
        <h1>
          дизайнерская пыль<br/>
          #1
        </h1>
        <div className="info">
          <p>
            внутренняя конференция о мыслях, переживаниях,<br/>
            и болях дизайн-команды Red Collar.<br/>
            27 июля 17:00, коворкинг + онлайн
          </p>
          <a href="#" onClick={openTally}>Записаться</a>
        </div>
      </div>

    </div>
  </div>);
}
