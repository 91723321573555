import {
  AdditiveBlending,
  BufferGeometry,
  CanvasTexture, Float32BufferAttribute,
  Mesh,
  MeshBasicMaterial, NearestFilter,
  PerspectiveCamera,
  PlaneBufferGeometry, Points, PointsMaterial,
  Scene, ShaderMaterial,
  WebGLRenderer
} from "three";
import { CursorTrail } from "./CursorTrail";

import DustFrag from './shaders/dust.frag.glsl';
import DustVert from './shaders/dust.vert.glsl';

export class DustScene {

  private readonly scene: Scene;

  private readonly camera: PerspectiveCamera;

  private readonly trail: CursorTrail;

  private portraits: HTMLImageElement | null = null;

  private readonly mesh: Points;

  private readonly meshMaterial: ShaderMaterial;

  public constructor(trail: CursorTrail) {
    this.trail = trail;
    this.scene = new Scene();

    this.camera = new PerspectiveCamera(45, 1, 0.1, 100);
    this.camera.position.set(0, 0, 2.42);

    this.meshMaterial = new ShaderMaterial({
      vertexShader: DustVert,
      fragmentShader: DustFrag,
      transparent: true,
      blending: AdditiveBlending,
      depthWrite: false,
      depthTest: false,
      uniforms: {
        pointSize: {
          value: 1
        },
        time: {
          value: 0
        },
        disappear: {
          value: 1
        },
        displaceMap: {
          value: this.trail.getTexture()
        }
      }
    })

    this.mesh = new Points(undefined, this.meshMaterial);
    this.scene.add(this.mesh);

  }

  public preloadImage(): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        this.portraits = img;
        resolve();
      };
      img.onerror = () => {
        reject();
      }
      img.src = '/portraits.jpg';
    })
  }

  public setFromIndex(index: number) {

    const canvas = document.createElement('canvas');
    canvas.width = canvas.height = 256;
    const g = canvas.getContext('2d');
    if (g && this.portraits) {
      const framesPerRow = this.portraits.width / 256;
      g.clearRect(0, 0, 256, 256);
      g.drawImage(this.portraits, -(index % framesPerRow) * 256, -Math.floor(index / framesPerRow) * 256);

      const position = [];
      const color = [];
      const angle = [];
      const offset = [];
      const disperse = [];
      const disperseAct = [];

      const data = g.getImageData(0, 0, 256, 256).data;
      for (let y = 0; y < 256; y++) {
        for (let x = 0; x < 256; x++) {
          const idx = (y * 256 + x) * 4;

          // Переводим в яркость
          const rgbMult = 1.0 / 255.0;
          const luminance =
            data[idx] * 0.2126 * rgbMult +
            data[idx + 1] * 0.7152 * rgbMult +
            data[idx + 2] * 0.0722 * rgbMult;

          if (luminance > 0.1) {
            position.push(
              (x / 128.0) - 1.0,
              -((y / 128.0) - 1.0),
              0
            );
            color.push(luminance * (0.8 + Math.random() * 0.2) * 0.7);
            angle.push(Math.random() * 2 * Math.PI);
            offset.push(Math.random());
            disperse.push((Math.random() * 1.4 + 1.0));
            disperseAct.push(Math.random() * 0.6 + 0.5);
          }
        }
      }

      const geom = new BufferGeometry();
      geom.setAttribute('position', new Float32BufferAttribute(position, 3, false));
      geom.setAttribute('luminance', new Float32BufferAttribute(color, 1, false));
      geom.setAttribute('angle', new Float32BufferAttribute(angle, 1, false));
      geom.setAttribute('offset', new Float32BufferAttribute(offset, 1, false));
      geom.setAttribute('disperse', new Float32BufferAttribute(disperse, 1, false));
      geom.setAttribute('disperseActive', new Float32BufferAttribute(disperseAct, 1, false));
      this.mesh.geometry = geom;

    }
  }

  public setDisappear(x: number) {
    this.meshMaterial.uniforms.disappear.value = Math.pow(x, 3);
  }

  public update(tween: number) {
    const t = this.meshMaterial.uniforms.time.value;
    this.meshMaterial.uniforms.time.value = (t + 0.003 * tween) % 1.0;
  }

  public render(renderer: WebGLRenderer) {
    renderer.render(this.scene, this.camera);
  }

  public resize(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.meshMaterial.uniforms.pointSize.value = height / 256 * 2.0;
  }

  public detach() {

  }

}
