import { run } from "jest";
import { useEffect, useRef, useState } from "react";
import { GraphicsManager } from "../lib/GraphicsManager";

interface WrapperProps {
  lookup: number[],
  onIndexChange: (index: number) => void
}

export function ParticleWrapper({ lookup, onIndexChange }: WrapperProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const managerRef = useRef<GraphicsManager | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const runTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (managerRef.current) {
        managerRef.current.cycle();
        runTimer();
      }
    }, 5000);
  }

  const handleClick = () => {
    if (managerRef.current) {
      managerRef.current.cycle();
      runTimer();
    }
  }

  useEffect(() => {
    if (wrapperRef.current && canvasRef.current) {
      managerRef.current = new GraphicsManager(
        wrapperRef.current,
        canvasRef.current,
        lookup,
        onIndexChange
      );
      runTimer();
      return () => {
        managerRef.current?.unbind();
        managerRef.current = null;
      }
    }
  }, [wrapperRef, canvasRef])

  return <div className="canvas-wrap" ref={wrapperRef} onClick={handleClick}>
    <canvas ref={canvasRef}/>
  </div>
}
