import { PerspectiveCamera, WebGLRenderer } from "three";
import { CursorTrail } from "./CursorTrail";
import { DustScene } from "./DustScene";


export class GraphicsManager {

  private rafQuery: number = -1;

  private rafTime: number = 0;

  private wrapper: HTMLDivElement;

  private canvas: HTMLCanvasElement;

  private renderer: WebGLRenderer;

  private dust: DustScene;

  private trail: CursorTrail;

  private active: boolean;

  private counter: number = 0;

  private index: number = -1;

  private targetIndex: number = -1;

  private indexLookup: number[];

  private onIndexChange: (index: number) => void

  public constructor(wrapper: HTMLDivElement, canvas: HTMLCanvasElement, indexLookup: number[], onIndexChange: (index: number) => void) {
    this.wrapper = wrapper;
    this.canvas = canvas;
    this.active = false;
    this.indexLookup = indexLookup;
    this.onIndexChange = onIndexChange;

    this.renderer = new WebGLRenderer({
      antialias: true,
      depth: false,
      canvas: this.canvas,
      powerPreference: 'high-performance'
    })

    this.trail = new CursorTrail(this.wrapper);
    this.dust = new DustScene(this.trail);

    this.resize = this.resize.bind(this);
    window.addEventListener('resize', this.resize);
    this.resize();

    this.rafTime = performance.now();
    this.handleFrame = this.handleFrame.bind(this);
    this.handleFrame(this.rafTime);

    this.dust.preloadImage().then(() => {
      this.index = 0
      this.onIndexChange(0);
      this.dust.setFromIndex(this.indexLookup[0]);
      this.active = true;
      this.counter = 0;
    });
  }

  public unbind() {
    window.removeEventListener('resize', this.resize);

    this.trail.detach();
    this.dust.detach();
  }

  public cycle() {
    if (this.active && this.index !== -1 && this.counter === 1) {
      this.targetIndex = (this.index + 1) % this.indexLookup.length;
      this.index = -1;
      this.onIndexChange(this.index);
    }
  }

  private handleFrame(time: number) {
    this.rafQuery = requestAnimationFrame(this.handleFrame);
    const delta = (time - this.rafTime) / 16.666;
    this.rafTime = time;

    if (this.active) {
      if (this.index === -1) {
        this.counter = Math.max(this.counter - 0.03 * delta, 0.0);
        if (this.counter <= 0) {
          this.index = this.targetIndex;
          this.dust.setFromIndex(this.indexLookup[this.index]);
          this.onIndexChange(this.index);
        }
      } else {
        this.counter = Math.min(this.counter + 0.02 * delta, 1.0);
      }
      this.dust.setDisappear(1.0 - this.counter);
    }

    this.trail.update(delta);
    this.dust.update(delta);

    this.renderer.setClearColor(0x222222);
    this.renderer.clear();
    this.dust.render(this.renderer);
  }

  private resize() {
    const w = this.wrapper.clientWidth * window.devicePixelRatio;
    const h = this.wrapper.clientHeight * window.devicePixelRatio;

    this.canvas.width = w;
    this.canvas.height = h;
    this.renderer.setSize(w, h, false);
    this.dust.resize(w, h);
  }

}
