import { CanvasTexture, ClampToEdgeWrapping, FloatType, Line, LinearFilter, RedFormat, Texture } from "three";

interface TrailPoint {
  x: number,
  y: number,
  time: number,
}

export class CursorTrail {

  private points: TrailPoint[] = [];

  private trailMask: boolean[] = [];

  private canvas: HTMLCanvasElement;

  private wrapper: HTMLDivElement;

  private texture: CanvasTexture;

  public constructor(wrapper: HTMLDivElement) {
    this.wrapper = wrapper;

    this.canvas = document.createElement('canvas');
    this.canvas.width = 512;
    this.canvas.height = 512;
    this.texture = new CanvasTexture(
      this.canvas,
      Texture.DEFAULT_MAPPING,
      ClampToEdgeWrapping,
      ClampToEdgeWrapping,
      LinearFilter,
      LinearFilter,
      RedFormat
    );

    this.handleMouse = this.handleMouse.bind(this);
    this.handleTouches = this.handleTouches.bind(this);
    this.wrapper.addEventListener('mousedown', this.handleMouse);
    this.wrapper.addEventListener('mousemove', this.handleMouse);
    this.wrapper.addEventListener('touchstart', this.handleTouches);
    this.wrapper.addEventListener('touchmove', this.handleTouches);
  }

  public detach() {
    this.wrapper.removeEventListener('mousedown', this.handleMouse);
    this.wrapper.removeEventListener('mousemove', this.handleMouse);
    this.wrapper.removeEventListener('touchstart', this.handleTouches);
    this.wrapper.removeEventListener('touchmove', this.handleTouches);
  }

  public update(delta: number) {
    this.points = this.points.map(item => {
      item.time += delta * 0.01;
      return item;
    }).filter(item => item.time < 1.0);

    const g = this.canvas.getContext('2d');
    if (g) {
      g.globalCompositeOperation = 'source-over';
      g.fillStyle = '#000';
      g.fillRect(0, 0, this.canvas.width, this.canvas.height);
      g.fillStyle = '#fff';
      g.globalCompositeOperation = 'screen';

      for (let p of this.points) {

        const rad = Math.sin(p.time * Math.PI * 0.6 + 0.1) * (128 * window.devicePixelRatio);
        const color = 1.0 - p.time;

        const grad = g.createRadialGradient(p.x, p.y, 0, p.x, p.y, rad);
        grad.addColorStop(1, `rgba(255, 255, 255, 0)`);
        grad.addColorStop(0.3, `rgba(255, 255, 255, ${color * 0.3})`);
        grad.addColorStop(0, `rgba(255, 255, 255, ${color})`);

        g.fillStyle = grad
        g.fillRect(p.x - rad, p.y - rad, rad * 2, rad * 2);
      }
      this.texture.needsUpdate = true;
    }
  }

  public getTexture(): Texture {
    return this.texture;
  }

  private handleMouse(event: MouseEvent) {
    const rect = this.wrapper.getBoundingClientRect();
    this.addPoint(event.clientX - rect.x, event.clientY - rect.y);
  }

  private handleTouches(event: TouchEvent) {
    const rect = this.wrapper.getBoundingClientRect();
    for (let i = 0; i < event.touches.length; i++) {
      const t = event.touches[i];
      this.addPoint(t.clientX - rect.x, t.clientY - rect.y);
    }
  }

  private addPoint(px: number, py: number) {
    const x = ((px - this.wrapper.clientWidth / 2) / (this.wrapper.clientHeight / 2)) * 256 + 256;
    const y = py / this.wrapper.clientHeight * 512;
    this.points.push({
      x, y,
      time: 0
    })
  }

}
