import { CSSProperties, useEffect, useRef, useState } from "react";
import { Rows } from "../config/Config";
import { BreakStruct } from "../config/ConfigTypes";

interface SlideProps {
  expanded: boolean
}

const ITEMS = Rows
  .map((item) => {
    const [hours, minutes] = item.time.split(':').map(g => parseInt(g));
    return {
      item: item,
      weight: hours * 60 + minutes
    }
  })
  .sort((a, b) => a.weight - b.weight)
  .map(ent => ent.item);

export function ContentSlide({expanded}: SlideProps) {
  const wrapRef = useRef<HTMLDivElement>(null);
  const [contentStyle, setContentStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (wrapRef.current) {
      setContentStyle({
        height: expanded ? `${wrapRef.current.clientHeight}px` : 0
      })
    }
  }, [wrapRef, expanded]);

  return <div className={'content'} style={contentStyle}>
    <div className="content__wrap" ref={wrapRef}>
      <div className="wrapper">
        <div className="content__info">
          внутренняя конференция о мыслях, переживаниях,<br/>
          и болях дизайн-команды Red Collar. <br/>
          27 июля 17:00, коворкинг + онлайн <br/>
        </div>
        <div className="line"/>
        <div className="content__grid">
          {
            ITEMS.map(item => {
              if ('name' in item) {
                return <div className="item" key={item.time}>
                  <div className="item__text">
                    {item.name}
                    <br/><br/>
                    {item.text}
                  </div>
                  <div className="item__time">
                    {item.time}
                  </div>
                </div>
              }
              return <div className="item break" key={item.time}>
                <div className="item__text">
                  перерыв
                </div>
                <div className="item__time">
                  {item.time}
                </div>
              </div>
            })
          }
        </div>
      </div>
      <div className="content__footer">
        &copy; Институт дизайна и отдыха «Красный Воротник»
      </div>
    </div>
  </div>
}
